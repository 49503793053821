import React, { useState } from "react"
import loadable from "@loadable/component"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))

function Chat() {
  const [hideSendBox, setHideSendBox] = useState(true)
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="regioit"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      botId="1c71315kkv9qqrwx"
      domain="https://europe.directline.botframework.com/v3/directline"
      webSocket={true}
      hasGDPR={false}
      onHideSendBox={value => setHideSendBox(value)}
      autoFocusVersion="v1"
      // gdprButtonText="Zustimmen"
      // gdprText={
      //   <>
      //     Die{" "}
      //     <a
      //       href="https://www.gegenbauer.de/datenschutzerklaerung/"
      //       target="_blank"
      //     >
      //       Datenschutzerklärung
      //     </a>{" "}
      //     habe ich zur Kenntnis genommen und ich erkläre mich damit
      //     einverstanden, dass meine Daten zum Zwecke der Bearbeitung meiner
      //     Anfrage verarbeitet werden. Ihre Einwilligung können Sie jederzeit
      //     widerrufen. Durch den Widerruf der Einwilligung wird die
      //     Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
      //     erfolgten Verarbeitung nicht berührt.
      //   </>
      // }
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#134d88",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        transcriptVisualKeyboardIndicatorWidth: 1,
        transcriptActivityVisualKeyboardIndicatorWidth: 1,
        // hideSendBox: true,
        // primaryFont:
        //   'muli,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Gesuchte Dienstleistung...",
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        "--button-red": "rgb(120, 125, 141)",
        "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
